const CONSTANTS = {
  LETS_TALK: "Let’s Talk",
  NEXTSTACK_LLC: "NextStack LLC",
  DEFAULT_MESSAGE: {
    response: "Hi! How can we help you?",
    icon: "/icons/hand.webp",
  },
  MY_QUESTION: "my question is",
  YOUR_ANSWER: "your answer is",
};

export default CONSTANTS;