export const faq = [
  {
    id: 1,
    label: "What services do you provide?",
  },
  {
    id: 2,
    label: "NextStack’s experience and portfolio",
  },
  {
    id: 3,
    label: "What technologies do you use?",
  },
  {
    id: 4,
    label: "Talk to sales",
  },
];